// Doc ready

const ready = (callback) => {

	if (document.readyState != 'loading') callback();
	else document.addEventListener('DOMContentLoaded', callback);

}

ready(() => {


	// BACK TO TOP + HEADER

	const topButton			= document.getElementById('top');
	const header			= document.getElementById('header-nav');
	const navbarCollapse	= document.getElementById('navbarCollapse');
	let headerHeight        = header.offsetHeight;

	window.onscroll = function () {

		scrollFunction();

	};

	function scrollFunction() {

		let headerHeight = header.offsetHeight;

		if (
			document.body.scrollTop > headerHeight ||
			document.documentElement.scrollTop > headerHeight
			) {

			topButton.style.display = 'block';
			header.classList.add('header-dark');

		} else {

			topButton.style.display = 'none';

			if (navbarCollapse.classList.contains('show')) {

				header.classList.add('header-dark');

			} else {

				header.classList.remove('header-dark');

			}
			
		}
	}

	// When the user clicks on the button, scroll to the top of the document
	topButton.addEventListener('click', backToTop);

	function backToTop() {

		document.body.scrollTop = 0;
		document.documentElement.scrollTop = 0;

	}


	// Navbar toggler background

	navbarCollapse.addEventListener('show.bs.collapse', function () {

		header.classList.add('header-dark');
		
	});

	navbarCollapse.addEventListener('hide.bs.collapse', function () {

		let headerHeight = header.offsetHeight;

		if (
			document.body.scrollTop > headerHeight ||
			document.documentElement.scrollTop > headerHeight
			) {

			header.classList.add('header-dark');

		} else {

			header.classList.remove('header-dark');

		}
	});



	// SMOOTH SCROLL TO ANCHORS

	const anchors = document.querySelectorAll('a[href^="#"]:not([href="#"], [href^="#collapseProgram"])');

	anchors.forEach(trigger => {

		trigger.onclick = function(e) {

			e.preventDefault();

			let hash				= this.getAttribute('href');
			let target				= document.querySelector(hash);
			let headerOffset		= header.offsetHeight;
			let elementPosition		= target.offsetTop;
			let offsetPosition		= elementPosition - headerOffset;

			window.scrollTo({

				top: offsetPosition,
				behavior: 'smooth'

			});
		};
	});



	// Venobox

	new VenoBox({
		selector: '.venobox',
		maxWidth: '65%',
		infinigall: true,
		spinColor: '#87c9b6',
		share: true
	});


});


